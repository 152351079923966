import { css } from 'styled-components';

import { CommonTheme } from 'app/shared/theme';
import { withChildSVGStyleCSS } from 'app/shared/components/styleUtils';

export interface WithButtonStyleProps {
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  cursor?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  controlChildSVGStyle?: boolean;
}

interface WithButtonStyleCSSProps extends WithButtonStyleProps {
  theme: CommonTheme;
}

const withPrimaryButtonStyleCSS = ({
  theme,
  disabled,
  controlChildSVGStyle,
}: WithButtonStyleCSSProps) => css`
  background: ${theme.colors.button.primary.background};
  color: ${theme.colors.button.primary.text};
  border: 1px solid ${theme.colors.button.primary.background} !important;
  ${
    controlChildSVGStyle &&
    withChildSVGStyleCSS({
      svgFillColor: 'transparent',
      svgStrokeColor: theme.colors.button.primary.text,
    })
  };

  &:focus {
    color: ${theme.colors.button.primary.text};
    background: ${theme.colors.button.primary.focus};
    border: ${theme.colors.button.primary.focus} !important;
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: 'transparent',
        svgStrokeColor: theme.colors.button.primary.text,
      })
    };
  }

  &:active {
    color: ${theme.colors.button.primary.altText};
    background: ${theme.colors.button.primary.active};
    border: ${theme.colors.button.primary.active} !important;
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: 'transparent',
        svgStrokeColor: theme.colors.button.primary.altText,
      })
    };
  }

  @media (hover: hover) {
    &:hover {
      color: ${theme.colors.button.primary.text};
      background: ${theme.colors.button.primary.hover};
      border: 1px solid ${theme.colors.button.primary.hover} !important;
      ${
        controlChildSVGStyle &&
        withChildSVGStyleCSS({
          svgFillColor: 'transparent',
          svgStrokeColor: theme.colors.button.primary.text,
        })
      };
    }
  }

  ${
    disabled &&
    css`
      color: ${theme.colors.button.primary.altText};
      background: ${theme.colors.button.primary.disabled};
      border: 1px solid ${theme.colors.button.primary.disabled} !important;
      ${
        controlChildSVGStyle &&
        withChildSVGStyleCSS({
          svgFillColor: 'transparent',
          svgStrokeColor: theme.colors.button.primary.altText,
        })
      };

      &:hover,
      &:focus,
      &:active {
        color: ${theme.colors.button.primary.altText};
        background: ${theme.colors.button.primary.disabled};
        border: 1px solid ${theme.colors.button.primary.disabled} !important;
        ${
          controlChildSVGStyle &&
          withChildSVGStyleCSS({
            svgFillColor: 'transparent',
            svgStrokeColor: theme.colors.button.primary.altText,
          })
        };
      }
    `
  }
`;

const withOutlineButtonStyleCSS = ({
  theme,
  disabled,
  variant = 'secondary',
  controlChildSVGStyle,
}: WithButtonStyleCSSProps) => css`
  background: ${theme.colors.button[variant].background};
  border: 1px solid ${theme.colors.button[variant].text} !important;
  color: ${theme.colors.button[variant].text};
  ${
    controlChildSVGStyle &&
    withChildSVGStyleCSS({
      svgFillColor: 'transparent',
      svgStrokeColor: theme.colors.button[variant].text,
    })
  };

  &:focus {
    background: ${theme.colors.button[variant].background};
    border: 1px solid ${theme.colors.button[variant].focus} !important;
    color: ${theme.colors.button[variant].focus};
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: 'transparent',
        svgStrokeColor: theme.colors.button[variant].focus,
      })
    };
  }

  &:active {
    background: ${theme.colors.button[variant].background};
    border: 1px solid ${theme.colors.button[variant].active} !important;
    color: ${theme.colors.button[variant].active};
    ${
      controlChildSVGStyle &&
      withChildSVGStyleCSS({
        svgFillColor: 'transparent',
        svgStrokeColor: theme.colors.button[variant].active,
      })
    };
  }

  @media (hover: hover) {
    &:hover {
      background: ${theme.colors.button[variant].background};
      border: 1px solid ${theme.colors.button[variant].hover} !important;
      color: ${theme.colors.button[variant].hover};
      ${
        controlChildSVGStyle &&
        withChildSVGStyleCSS({
          svgFillColor: 'transparent',
          svgStrokeColor: theme.colors.button[variant].hover,
        })
      };
  }

  ${
    disabled &&
    css`
      background: ${theme.colors.button[variant].background};
      border: 1px solid ${theme.colors.button[variant].disabled} !important;
      color: ${theme.colors.button[variant].disabled};
      ${
        controlChildSVGStyle &&
        withChildSVGStyleCSS({
          svgFillColor: 'transparent',
          svgStrokeColor: theme.colors.button[variant].disabled,
        })
      };

      &:hover,
      &:focus,
      &:active {
        background: ${theme.colors.button[variant].background};
        border: 1px solid ${theme.colors.button[variant].disabled} !important;
        color: ${theme.colors.button[variant].disabled};
        ${
          controlChildSVGStyle &&
          withChildSVGStyleCSS({
            svgFillColor: 'transparent',
            svgStrokeColor: theme.colors.button[variant].disabled,
          })
        };
      }
    `
  }
  `;

export const withButtonStyleCSS = ({
  theme,
  isLoading = false,
  disabled = false,
  cursor = 'pointer',
  variant = 'primary',
  fullWidth,
  controlChildSVGStyle = true,
}: WithButtonStyleCSSProps) => css`
  font-family: ${theme.fonts.regular};
  font-weight: ${theme.fontWeights.bold};
  letter-spacing:  ${theme.letterSpacings.button};
  line-height: 100%;
  text-transform: uppercase;
  text-decoration: none;
  padding: ${theme.ruler[3]}px ${theme.ruler[6]}px;
  border-radius: 60px;
  cursor: ${cursor};
  outline: inherit;
  box-sizing: border-box;

  width: ${fullWidth ? '100%' : 'fit-content'};
  min-width: max-content;
  height: ${theme.dimensions.button.height} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: ${theme.ruler[2]}px;

  transition: all 400ms;

  ${theme.media.mobile`
    font-size: ${theme.fontSizes.fontSize100.mobile};
  `};

  ${theme.media.smallDesktop`
    font-size: ${theme.fontSizes.fontSize100.desktop};
  `};

  ${
    disabled &&
    css`
      cursor: disabled;
      pointer-events: none;
    `
  }

  ${
    variant === 'primary' &&
    withPrimaryButtonStyleCSS({
      theme,
      variant,
      disabled: disabled && !isLoading,
      controlChildSVGStyle: controlChildSVGStyle && !isLoading,
    })
  }

  ${
    (variant === 'secondary' || variant === 'tertiary') &&
    withOutlineButtonStyleCSS({
      theme,
      variant,
      disabled: disabled && !isLoading,
      controlChildSVGStyle: controlChildSVGStyle && !isLoading,
    })
  };
`;
